.menu-detail {
  color: #626161;
  button {
    border: none;
    outline: none;
    background-color: unset;
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
    }
  }

  .box {
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 25px;

    @media only screen and (min-width: 768px) {
      padding: 10px 30px;
    }

    .area-number {
      display: flex;
      width: 100%;
      justify-content: space-between;
      @media only screen and (min-width: 768px) {
        width: 25%;
      }
      @media only screen and (min-width: 960px) {
        width: 100%;
      }
      @media only screen and (min-width: 1200px) {
        width: 25%;
      }
      p {
        font-size: 18px;
        color: #626161;
        font-weight: 600;
        margin: 0;
      }
    }

    .area-price {
      cursor: pointer;
      background-color: #F79238;
      align-items: center;
      padding: 10px 20px;
      border-radius: 30px;
      color: #fff;
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin: 10px 0;
      @media only screen and (max-width: 576px) {
        width: calc(100% - 112px);
        order: 2;

        span {
          font-size: 14px !important;
        }
      }
      @media only screen and (min-width: 768px) {
        width: 45%;
        margin: 0;
      }
      @media only screen and (min-width: 960px) {
        width: 100%;
        margin: 10px 0;
      }
      @media only screen and (min-width: 1200px) {
        width: 45%;
        margin: 0;
      }

      span {
        font-size: 14px;
        font-weight: bold;

        &:last-child {
          font-size: 16px;
        }
      }
    }

    .area-heart {
      color: #d1402a;
      padding: 10px 14px 8px;
      border: 1px solid #d1402a;
      border-radius: 30px;
    }
  }

  .area-desc {
    color: #626161;
    font-size: 14px;
    line-height: 21px;
    > p,
    span {
      margin: 0;
    }
  }

  .area-extra {
    margin-bottom: 15px;
    &__content {
      > div {
        display: flex;
        justify-content: space-between;
        @media only screen and (min-width: 992px) {
          width: 60%;
        }
        > div {
          display: flex;
          align-items: center;
        }
      }
    }

    &__avatar {
      display: inline-block;
      width: 37px;
      height: 37px;
      border-radius: 50%;
      background-color: #c4c4c4;
      margin-right: 15px;
    }
  }
}
