.menu-card {
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 15px;
  align-items: center;
  height: 80px;
  padding-right: 10px;
  overflow: hidden;

  &__name {
    margin: 0;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: #626161;
    font-size: 14px;
    font-weight: 500;
  }

  &__text {
    padding-left: 10px;
    > p {
      font-size: 14px;
      margin: 0;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      &:first-of-type {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 5px;
        -webkit-line-clamp: 1;
      }

      p,
      span {
        margin: 0;
        color: #000 !important;
        font-size: 14px !important;
      }
    }
  }

  &__price {
    text-align: right;
    .eyeColor {
      color: #F79238;
    }

    > p {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
    }
  }
}
