.cookie_policy {
  padding: 23px 0 50px;
  &--content {
    padding: 0 0 60px;
    p {
      font-weight: 300;
      font-size: 14px;
      line-height: 21px;
      color: #000000;
    }
  }
}
