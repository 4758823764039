.tabs__Customer {
  padding-top: 22px;
  padding-bottom: 22px;

  .MuiTabs-scroller > span {
    background-color: transparent !important;
  }
  .wrap {
    font-family: "Poppins", sans-serif;
    display: flex;
    margin: auto;
    @media screen and (max-width: 768px) {
      .wrap__tab--left {
        width: 40%;
      }
      .wrap__tab--right {
        width: 60%;
      }
    }
    @media screen and (max-width: 1204px) {
      .wrap__tab--left {
        margin-right: 20px;
      }
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
      .wrap__tab--left,
      .wrap__tab--right {
        width: 100%;
        margin: auto;
      }
    }
    &__tab--left {
      width: 36%;
      .tabs__menu__customer {
        &--children {
          max-width: 388px;
          margin-bottom: 13px;
          border: 2px solid #F79238;
          border-radius: 30px;
          color: #F79238;
          font-weight: bold;
          font-family: "Poppins", sans-serif;
          font-size: 18px;
          margin-left: 0;
          .MuiTab-wrapper {
            display: flex;
            align-items: center;
            flex-direction: row;
          }
          .MuiSvgIcon-root {
            margin-right: 10px;
            margin-bottom: 0;
          }
          @media screen and (max-width: 578px) {
            width: 100%;
            margin: 0 auto 15px;
          }
        }
        .MuiTab-textColorInherit {
          opacity: inherit;
        }
        .MuiTab-labelIcon {
          min-height: 50px;
          padding-top: 7px;
        }
        .PrivateTabIndicator-root-3 {
          display: none;
        }
      }
    }

    &__tab--right {
      width: 64%;
      .MuiBox-root {
        padding: 0;
      }
      .title__favorites {
        display: flex;
        align-items: center;
        font-weight: bold;
        color: #626161;
        font-family: "Poppins", sans-serif;
        margin: 0px auto 15px;
        @media screen and (max-width: 578px) {
          padding-left: 15px;
        }
        & > :first-child {
          margin-right: 5px;
          color: #959292;
        }
      }
      .wrap__favorites {
        @media screen and (max-width: 578px) {
          width: 100%;
          margin: 0;
        }
        .favorites__restaurant {
          & > a {
            background: #fff;
            border-radius: 10px;
            & > div {
              padding-bottom: 10px;
            }
          }
          cursor: pointer;
          width: 25%;
          // max-width: 200px;
          @media screen and (max-width: 768px) {
            width: 50%;
          }
          display: flex;
          flex-direction: column;
          border-radius: 10px;
          img {
            width: 100%;
            height: 60%;
            border-radius: 10px;
          }
          .wrap__name {
            & > * {
              font-size: 12px;
              margin: 0 8px;
              font-family: "Poppins", sans-serif;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
            & > :first-child {
              font-weight: bold;
              -webkit-line-clamp: 1;
            }
            > div {
              -webkit-line-clamp: 2;
            }
          }
        }
      }
    }
  }
}

.customer_notification {
  .MuiButtonBase-root {
    border-radius: 10px;
    &.seen {
      .MuiButton-label {
        color: #959292;
      }
    }
    margin-bottom: 20px;
    background: #fff;
    width: 100%;
    text-align: left;
    padding: 20px;
    .MuiButton-label {
      justify-content: start;
      align-items: center;
      span {
        color: #000;
      }
      svg {
        fill: #959292;
        margin-right: 23px;
      }
    }
  }
}

.customer_my_order {
  width: 100%;
  p,
  h5 {
    margin: 0;
  }
  &--title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    width: 100%;
    .title--left {
      h5 {
        font-weight: 500;
        font-size: 18px;
        line-height: 15px;
        color: #F6923D;
      }
      span {
        font-weight: normal;
        font-size: 11px;
        line-height: 16px;
        color: #626161;
      }
    }
    .title--right {
      display: flex;
      align-items: center;
      .MuiButtonBase-root {
        background: #F79238;
        color: #fff;
        font-weight: bold;
        width: 168px;
        border-radius: 30px;
        margin-right: 15px;
        padding: 10px 8px;
      }
      &--info {
        padding-top: 5px;
        text-align: right;
        p {
          font-weight: 600;
          font-size: 14px;
          line-height: 10px;
          color: #626161;
        }
        span {
          font-weight: normal;
          font-size: 11px;
          color: #626161;
        }
      }
    }
  }
  &--dish {
    .MuiCard-root {
      border-radius: 10px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 25px;
      .dish--left {
        display: flex;
        align-items: center;
        img {
          border-radius: 10px;
          width: 79px;
          height: 100%;
        }
        &--info {
          margin-left: 11px;

          p,
          span {
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            color: #000 !important;
          }
          > div {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
          > p {
            font-weight: bold;
          }
          @media (max-width: 576px) {
            p,
            span {
              line-height: 12px;
            }
          }
        }
      }
      .dish--right {
        text-align: right;
        p,
        span {
          color: #000;
        }
        p {
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
  }
  &--price {
    margin-top: 17px;
    .price--wrapper {
      display: flex;
      justify-content: space-between;
      span {
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        text-align: right;

        color: #626161;
      }
    }
  }
  @media (max-width: 768px) {
    &--title {
      flex-wrap: wrap;
      .title--right {
        width: 100%;
        justify-content: space-between;
        .MuiButtonBase-root {
          order: 1;
          margin: 0;
        }
        &--info {
          order: 0;
        }
      }
    }
  }
  &--status {
    margin-top: 40px;
    h5 {
      display: flex;
      align-items: center;
      color: #d1402a;
      font-weight: 500;
      svg {
        margin-right: 20px;
      }
    }

    .step {
      margin-bottom: 55px;
      position: relative;
      &::before {
        content: "";
        width: 1px;
        background: #c4c4c4;
        height: 43px;
        position: absolute;
        left: 12px;
        top: 25px;
      }
      &--wrapper {
        display: flex;
        cursor: pointer;
        &:nth-child(1) {
          margin-bottom: 20px;
        }
      }
      &--icon {
        &.active {
          background: #F79238;
        }
        width: 25px;
        height: 25px;
        border-radius: 50%;
        color: #fff;
        box-sizing: border-box;
        padding-top: 3px;
        text-align: center;
        margin-right: 20px;
        background: #959292;
        svg {
          width: 18.92px;
          height: auto;
        }
      }
      &--desc {
        margin-top: 5px;
        p {
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          color: #626161;
        }
      }
    }
  }
}

.my_order_feedback {
  p {
    margin: 0;
  }
  &--title {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    .rate_star {
      margin-right: 17px;
      width: 50px;
      height: 37px;
      border-radius: 10px;
      background: #d1402a;
      display: flex;
      align-items: center;
      color: #fff;
      justify-content: center;
      span {
        margin-right: 2px;
      }
      svg {
        width: 18.33px;
        height: 18.33px;
      }
    }
    p {
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      color: #626161;
    }
  }
  .form_feedback {
    margin-bottom: 27px;

    .MuiCard-root {
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      padding: 33px 32px 23px 27px;
      @media (max-width: 576px) {
        padding: 20px 15px 25px;
      }
      .MuiOutlinedInput-root {
        margin-top: 10px;
        &.Mui-focused {
          fieldset {
            border-color: #d1402a;
          }
        }
      }
      .MuiRating-root {
        margin: 12.58px 0 17px;
        & > span {
          .MuiRating-icon {
            color: #dab480;
            &.MuiRating-iconFilled {
              color: #d1402a;
            }
          }
          font-size: 40px;
        }
      }
      .MuiButtonBase-root {
        width: 131px;
        height: 44px;
        border-radius: 30px;
        background: #F79238;
        font-weight: bold;
        font-size: 14px;
        line-height: 21px;
        color: #ffffff;
      }
    }
    &--title {
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #626161;
    }
  }
}
